/* eslint-disable react/require-default-props */
import { h } from 'preact';
import classNames from 'classnames';
import remoteFileObjToLocal from '@uppy/utils/lib/remoteFileObjToLocal';
import { useMemo } from 'preact/hooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore untyped
import VirtualList from '@uppy/utils/lib/VirtualList';
import SearchFilterInput from "./SearchFilterInput.js";
import FooterActions from "./FooterActions.js";
import Item from "./Item/index.js";
const VIRTUAL_SHARED_DIR = 'shared-with-me';
function ListItem(props) {
  const {
    currentSelection,
    uppyFiles,
    viewType,
    isChecked,
    toggleCheckbox,
    recordShiftKeyPress,
    showTitles,
    i18n,
    validateRestrictions,
    getNextFolder,
    f
  } = props;
  if (f.isFolder) {
    return Item({
      showTitles,
      viewType,
      i18n,
      id: f.id,
      title: f.name,
      getItemIcon: () => f.icon,
      isChecked: isChecked(f),
      toggleCheckbox: event => toggleCheckbox(event, f),
      recordShiftKeyPress,
      type: 'folder',
      // TODO: when was this supposed to be true?
      isDisabled: false,
      isCheckboxDisabled: f.id === VIRTUAL_SHARED_DIR,
      // getNextFolder always exists when f.isFolder is true
      handleFolderClick: () => getNextFolder(f)
    });
  }
  const restrictionError = validateRestrictions(remoteFileObjToLocal(f), [...uppyFiles, ...currentSelection]);
  return Item({
    id: f.id,
    title: f.name,
    author: f.author,
    getItemIcon: () => viewType === 'grid' && f.thumbnail ? f.thumbnail : f.icon,
    isChecked: isChecked(f),
    toggleCheckbox: event => toggleCheckbox(event, f),
    isCheckboxDisabled: false,
    recordShiftKeyPress,
    showTitles,
    viewType,
    i18n,
    type: 'file',
    isDisabled: Boolean(restrictionError) && !isChecked(f),
    restrictionError
  });
}
function Browser(props) {
  const {
    currentSelection,
    folders,
    files,
    uppyFiles,
    viewType,
    headerComponent,
    showBreadcrumbs,
    isChecked,
    toggleCheckbox,
    recordShiftKeyPress,
    handleScroll,
    showTitles,
    i18n,
    validateRestrictions,
    isLoading,
    showSearchFilter,
    search,
    searchTerm,
    clearSearch,
    searchOnInput,
    searchInputLabel,
    clearSearchLabel,
    getNextFolder,
    cancel,
    done,
    noResultsLabel,
    virtualList
  } = props;
  const selected = currentSelection.length;
  const rows = useMemo(() => [...folders, ...files], [folders, files]);
  return h("div", {
    className: classNames('uppy-ProviderBrowser', `uppy-ProviderBrowser-viewType--${viewType}`)
  }, headerComponent && h("div", {
    className: "uppy-ProviderBrowser-header"
  }, h("div", {
    className: classNames('uppy-ProviderBrowser-headerBar', !showBreadcrumbs && 'uppy-ProviderBrowser-headerBar--simple')
  }, headerComponent)), showSearchFilter && h("div", {
    class: "uppy-ProviderBrowser-searchFilter"
  }, h(SearchFilterInput, {
    search: search,
    searchTerm: searchTerm,
    clearSearch: clearSearch,
    inputLabel: searchInputLabel,
    clearSearchLabel: clearSearchLabel,
    inputClassName: "uppy-ProviderBrowser-searchFilterInput",
    searchOnInput: searchOnInput
  })), (() => {
    if (isLoading) {
      return h("div", {
        className: "uppy-Provider-loading"
      }, h("span", null, typeof isLoading === 'string' ? isLoading : i18n('loading')));
    }
    if (!folders.length && !files.length) {
      return h("div", {
        className: "uppy-Provider-empty"
      }, noResultsLabel);
    }
    if (virtualList) {
      return h("div", {
        className: "uppy-ProviderBrowser-body"
      }, h("ul", {
        className: "uppy-ProviderBrowser-list"
      }, h(VirtualList, {
        data: rows,
        renderRow: f => h(ListItem, {
          currentSelection: currentSelection,
          uppyFiles: uppyFiles,
          viewType: viewType,
          isChecked: isChecked,
          toggleCheckbox: toggleCheckbox,
          recordShiftKeyPress: recordShiftKeyPress,
          showTitles: showTitles,
          i18n: i18n,
          validateRestrictions: validateRestrictions,
          getNextFolder: getNextFolder,
          f: f
        }),
        rowHeight: 31
      })));
    }
    return h("div", {
      className: "uppy-ProviderBrowser-body"
    }, h("ul", {
      className: "uppy-ProviderBrowser-list",
      onScroll: handleScroll,
      role: "listbox"
      // making <ul> not focusable for firefox
      ,
      tabIndex: -1
    }, rows.map(f => h(ListItem, {
      currentSelection: currentSelection,
      uppyFiles: uppyFiles,
      viewType: viewType,
      isChecked: isChecked,
      toggleCheckbox: toggleCheckbox,
      recordShiftKeyPress: recordShiftKeyPress,
      showTitles: showTitles,
      i18n: i18n,
      validateRestrictions: validateRestrictions,
      getNextFolder: getNextFolder,
      f: f
    }))));
  })(), selected > 0 && h(FooterActions, {
    selected: selected,
    done: done,
    cancel: cancel,
    i18n: i18n
  }));
}
export default Browser;